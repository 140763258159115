import { FC } from 'react';
// Interfaces
import { IconSvgProps } from '@utmb/types/icons';

const Right: FC<IconSvgProps> = ({ color, clss }) => {
    const styles = {
        svg: {
            verticalAlign: 'middle',
            overflow: 'hidden',
        },
    };

    return (
        <svg style={{ ...styles.svg, transform: 'rotate(90deg)' }} className={clss} width="20" height="16" viewBox="0 -2 17 13">
            <g fill="none" fillRule="evenodd">
                <g fill={color}>
                    <g>
                        <path d="M8.165-1.455L2.64 3.079c-.28.242-.304.654-.086.916.218.263.656.304.919.086L7.932.427V13.95c0 .357.29.648.65.648.359 0 .65-.29.65-.648V.427l4.458 3.654c.264.218.698.174.92-.086.233-.274.177-.699-.087-.916L8.998-1.455c-.316-.202-.554-.176-.833 0" />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default Right;
